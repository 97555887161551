import { TweenLite, TimelineLite, Back, TimelineMax/*, Power4, Power0, Elastic, Linear*/ } from 'gsap';
//This i need for a plane animation: MorphSVGPlugin

import $ from 'jquery';

// Placeholder for production builds only
let SplitText = function() {};

// Load SplitText from gatsby-browser.js (referenced on window)
if (typeof window !== 'undefined') {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    SplitText = window.SplitText;
  }
  
}

export default function newHomesPageMotion() {

  console.log('start new homes');
  // Condition to not perform ANY animations when building for production (just when opened in browser)
  if (typeof document !== 'undefined') {
    setTimeout(() => {

      let windowWidth = $(window).width();

      if(windowWidth < 992) {
        var Cont={val:0} , NewVal = 1000 ;

        var newHomesAnimations = new TimelineLite();
        newHomesAnimations
        .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
        .to(Cont,.5,{val:NewVal,roundProps:"val", onUpdate:function(){
          document.getElementById("counting").innerHTML=Cont.val
        }}, "-=0.8")

      } else {
        var Cont={val:0} , NewVal = 1000 ;

        var newHomesAnimations = new TimelineLite();
        newHomesAnimations
        .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
        .set('.grid', {opacity: 1})
        .staggerFrom('.leftSection > *', 0.8, { opacity: 0, y: 10, ease: Back.easeOut }, 0.1, "-=0")
        .from('.secondCircle', 0.8, { opacity: 0, scale: 0.9, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.4")
        .to(Cont,1.5,{val:NewVal,roundProps:"val", onUpdate:function(){
          document.getElementById("counting").innerHTML=Cont.val
        }}, "-=0.8")
        .from('.thirdCircle', 0.8, { opacity: 0, scale: 0.9, y: 30, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.4")
        .from('.firstCircle', 0.8, { opacity: 0, scale: 0.9, y: -30, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.8")
      }

      

      

    }, 0);
  }
}



//Scroll Animations 
export function functionAboutContact() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var aboutContactCta = new TimelineLite({ delay: .5 }),
    mySplitText = new SplitText(".aboutContactSectionTitle", { type: "words,chars" }),
    chars = mySplitText.chars;
  
    TweenLite.set(".aboutContactSectionTitle", { perspective: 400 });
  
    aboutContactCta
      .set('.aboutContactSectionColContent', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .staggerFrom(".aboutContactSection button, .aboutContactSection .aboutContactSectionDescription", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}


export function functionSlider() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.propeprtySlider', {opacity: 1})
      .staggerFrom(".propeprtySliderInner", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}

export function functionSliderbyID() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.propeprtySlider', {opacity: 1})
      .staggerFrom(".propeprtySliderInner", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}

export function functionGetAdvice() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.sectionGetAdvice', {opacity: 1})
      .staggerFrom(".sectionGetAdviceInner > *", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}