import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  SinglePropertySimilarSlider,
  NewhomesContact,
  RentStepByStep,
  NewHomesGetAdvice,
} from 'components/sections';
import { NewHomesHomeBadge } from 'components/ui';
import { NewHomesContactForm, TripleImageSection } from 'components/blocks';

import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutContact, functionSlider, functionGetAdvice } from 'motion/new-homes.js';
import { tripleImageSectionScroll } from 'motion/buy.js';
import { functionTimeline } from 'motion/rent.js';
import styled from 'styled-components';
import sc from 'config/theme';
import bp from 'config/responsive';



const Container = styled.div`
  background: #0b1d3f;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;


  ${bp.computer} {
    padding-top: 0px;
    padding-bottom: 100px;
    min-height: 70vh;
  }
`


const mockData = {
  landing: {
    title: 'Off Plan & New Homes Assignment Specialists',
    text: 'Buying off-plan is a great way for investors and home owners to put their capital in a worth while investment, especially in a newly built residence with modern comforts set within a vibrant city, which comes with its own renowned perks. With years of experience within the new homes and assignments sectors, our team is the perfect choice for anyone considering any type of acquisition of off-plan property or better portfolio management. ',
    buttonText: 'See our developments',
    badges: {
      newHomesLabel: 'New Homes',
      awardWinning: 'Award Winning Team',
      fiveStarReviews: 'Five Star Reviews',
    },
  },
  contactForm: {
    title: 'Do you own an off plan new property?',
    description: 'If you own an off plan new property and are curious of its market value, fill in the form below and one of our consultants shall get back to you with a valuation.',
    fields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Telephone',
      email: 'E-mail',
      price: 'Original purchase price',
      plotNumber: 'Plot number',
      address: 'Development, Address',
      moreInfo: 'Any additional information',
      gdprCheckbox: 'Agree to GDPR',
      submit: 'Submit',
    },
    errorMessage: 'There was an error sending your form.',
  },
  similarPropertiesText: {
    title: 'New Developments',
    description: '',
  },
  stepsData: {
    title: 'Why choose to live in a newly built home?',
    steps: [
      {
        title: 'Affordability',
        content: `When buying a new home allows you to secure the best price within a growing area, including the possibility of taking advantage of mortgage financing perks made available through your lender or building company.`,
      },
      {
        title: 'First owner',
        content: 'To be the first occupant to reside in a brand new property is always a great feeling. When you choose to buy a new home you can customize it to define your lifestyle. This helps create a living space specifically tailored to the home buyer’s taste.',
      },
      {
        title: 'Modify',
        content: `Many property developers allow buyers to participate in the process of designing their home. You can choose your own appliances, select your own finishes, colour palette and even your view! `,
      },
      {
        title: 'Payment',
        content: `You can reserve new homes with low deposits or staggered payment structures. There is also the option to re-sell and assign your contract before completion.`,
      },
      {
        title: 'Maintenance',
        content: `New build homes are built to a much higher standard nowadays and are very Eco friendly. With ever increasing energy costs, it is an important factor to consider when buying a new home as it could help save you money in the long term. They also typically come with a 10 year national new home warranty.`,
      },
      {
        title: 'Easy Living',
        content: `New homes usually come with a lot of perks, including all inclusive facilities such as; 24hr concierge services, state of the art gyms & residents business lounge.`,
      },
    ],
  },
  getAdvice: {
    title: 'Get expert property advice',
    content: 'If you are thinking of buying a property, get free expert advice from our experienced and helpful property consultants.',
    buttonText: 'Get in touch',
  },
  contactCta: {
    left: {
      title: 'Join the team',
      content: 'Do you think you have what it takes to be part of our award winning team?',
    },
    right: {
      title: 'Refer a friend',
      content: 'Receive a reward for referring your friends and family members to our trusted property managers',
    },
  },
}

const NewDevelopmePage = ({ data }) => (
  <Layout>
    <SEO title="New Homes" description="Buying off-plan is a great way for investors and home owners to put their capital in a worth while investment, especially in a newly built residence with modern comforts set within a vibrant city, which comes with its own renowned perks. With years of experience within the new homes and assignments sectors, our team is the perfect choice for anyone considering any type of acquisition of off-plan property or better portfolio management." />

    <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
    <Container>
      <TripleImageSection
        imageBack={data.imageBack.childImageSharp.fixed}
        imageMiddle={data.imageMiddle.childImageSharp.fixed}
        imageFront={data.imageFront.childImageSharp.fixed}

        imageBackalt=""
        imageMiddlealt=""                    
        imageFrontalt=""
      >
        <NewHomesContactForm {...mockData.contactForm} />
      </TripleImageSection>
     </Container>
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionSlider}>
      <SinglePropertySimilarSlider
        content={mockData.similarPropertiesText}
      />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionTimeline}>
      <RentStepByStep {...mockData.stepsData} />
    </ViewportEnterAnimation>

    <NewHomesHomeBadge />

    <ViewportEnterAnimation offset={100} animation={functionGetAdvice}>
      <NewHomesGetAdvice {...mockData.getAdvice} />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionAboutContact}>
      <NewhomesContact content={data.pages.newhomes.contactFooter}  />
    </ViewportEnterAnimation>
  </Layout>
)

export const query = graphql`
  query NewDevelopmePage {
    pages {
      newhomes {
        contactFooter{
          join{
            img
            title
            description
          }
          refer{
            img
            title
            description
          }
        }
      }
    } 
    imageBack: file(relativePath: { eq: "new-homes-t-back.jpg" }) {
      childImageSharp {
        fixed(width: 456, height: 314) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imageMiddle: file(relativePath: { eq: "new-homes-t-middle.jpg" }) {
      childImageSharp {
        fixed(width: 346, height: 386) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imageFront: file(relativePath: { eq: "new-homes-t-front.jpg" }) {
      childImageSharp {
        fixed(width: 416, height: 649) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default NewDevelopmePage;
